import React from 'react';
import { graphql,  Link } from 'gatsby';
import { deriveHomePathFromSpace, isNullUndefined, capitalizeFirstLetter } from "../../util"
import styles from "../../components/innerPage.module.css"
import teamStyles from "../../components/teams.module.css"
import Footer from '../../components/Investor/footer';
import Header from '../../components/Investor/header';
import ShowMoreText from 'react-show-more-text';
import SEO from "../../components/seo"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryCardsOnlyTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulCardsOnlyTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                bannerImage {
                    file {
                        url
                    }
                }
                spaceId
                slug
                title
                parentMenu {
                    cards_only_template {
                      parentMenu {
                        title
                        slug
                      }
                    }
                }
                cardLayout {
                    description
                    title
                    spaceId
                    file {
                      url
                    }
                }
        }
    }
`

const CardsOnlyTemplatePageInvestor = (props) => {

    const {bannerImage, parentMenu, slug, spaceId, title, cardLayout} = props.data.contentfulCardsOnlyTemplate;

    const homePath = (() => deriveHomePathFromSpace(spaceId))()
    React.useEffect(() => {
    }, []);
    return ( 
        <TemplateWrapper>
            <Header/>
         <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
        <div className={styles.banner}  style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
        <div className={styles.overlay}></div>
                              <div className="container">
                                 <div className="row">
                                     <div className="col-12 col-sm-12 col-md-12">
                                      
                                     <h1 className="">
                                         {title}
                                     </h1>
                                     <ol className="custom_breadcrumb">
                                     <li className="breadcrumb-item"><Link to="/Investor/home">Home</Link></li>
                                     {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.cards_only_template) && <li className="breadcrumb-item"><a href="#">
                                            {parentMenu.cards_only_template[0].parentMenu.title}    
                                        </a></li>}
                                          <li className="breadcrumb-item active" aria-current="page">
                                              {title}
                                          </li>
                                      </ol>
                                   
                                     </div>
                                  
                                     </div>
                              </div>
                  </div>
             <div className="container">
             <div className="row">
  

        {!isNullUndefined(cardLayout) && cardLayout.map((item, index) => {
                return (
                    <div key={index} className="col-12 col-sm-12 col-md-12 col-lg-4">
                    <div className={teamStyles.teams_card}>
                        <div className="row">
                        <div className="col-12 col-sm-12 col-md-12"> 
                            <div className={teamStyles.speaker_img}>
                            <img width="100%" src={item.file.url}/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12">
                            <h4 className="mb-1">
                                {item.title}
                            </h4>
                            <ShowMoreText
                                lines={3}
                                more='Show more'
                                less='Show less'
                                anchorClass=''
                            >
                                <span className="mb-1">
                                    {`${item.description}`}
                                </span>
                    
                            </ShowMoreText>
                
                    
                        </div>
                        </div>
                    </div>
                </div>
                )
        })}
  
  
  
  </div>
  
             </div>
             <Footer/>
        </TemplateWrapper>
    );
}

export default CardsOnlyTemplatePageInvestor;